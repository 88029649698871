export const tamilTranslations: any = {
  LOGIN_PAGE: {
    HEADER: "உள்நுழைய",
    SUBTITLE: "மீண்டும் வருக! உங்கள் கணக்கில் உள்நுழையவும்.",
    HELP: "உதவி",
    USERNAME: "பயனர் பெயர்",
    PASSWORD: "கடவுச்சொல்",
    FORGOT_PASSWORD: "கடவுச்சொல்லை மறந்துவிட்டீர்களா",
    TERMS_CONDITIONS:
      "உள்நுழைவதன் மூலம், விதிமுறைகளையும் நிபந்தனைகளையும் ஏற்கிறேன்",
    RESET: "மீட்டமை",
  },
  CAPTCHA: {
    ENTER_TEXT: "உரையை உள்ளிடவும்",
    REFRESH: "புதுப்பிப்பு",
  },
  HEADER: {
    HELPDESK: "உதவி மையம்",
  },
  FOOTER: {
    ALL_RIGHTS_RESERVED:
      "நிதித்துறை, தமிழ்நாடு அரசு. அனைத்து உரிமைகளும் பாதுகாக்கப்பட்டவை.",
    DESIGNED_TCS:
      "டாடா கன்சல்டன்சி சர்வீசஸ் லிமிடெட் வடிவமைத்து, உருவாக்கி, பராமரிக்கிறது.",
  },
};
