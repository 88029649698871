<!-- <img class="bg" src="assets/images/gradientbg.png" /> -->

<loader></loader>

<updated-header></updated-header>

<!-- <top-strip></top-strip> -->

<!-- <header></header> -->

<div class="router-outlet-container">
  <router-outlet></router-outlet>
</div>

<app-footer></app-footer>
