<div id="captcha-form-desktop">
  @if(captchaBase64Text) {
  <div>
    <img
      id="captcha"
      alt="captcha image generated for authentication"
      src="data:image/jpeg;base64,{{ captchaBase64Text }}"
    />
  </div>
  } @else {
  <div class="placeholder"></div>
  }
  <div>
    <mat-form-field>
      <mat-label>{{ "CAPTCHA.ENTER_TEXT" | translate }}</mat-label>
      <input
        matInput
        name="captcha-value"
        required
        [(ngModel)]="captchaValue"
        (ngModelChange)="storeCaptchaAnswerInSession()"
        autocomplete="off"
        maxlength="6"
      />
      <div matSuffix>
        <button mat-button type="button" (click)="loadNewCaptcha()">
          <mat-icon>refresh</mat-icon>
          <span>&nbsp;&nbsp;{{ "CAPTCHA.REFRESH" | translate }}</span>
        </button>
      </div>
    </mat-form-field>
  </div>
</div>
