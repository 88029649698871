import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./login/login.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "set-pw",
    // loadChildren: () =>
    //   import("./set-pw/set-pw.module").then((m) => m.SetPwModule),
    loadComponent: () =>
      import("./password-mgmt/password-mgmt.component").then(
        (c) => c.PasswordMgmtComponent
      ),
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes)
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
