export const hindiTranslations: any = {
  LOGIN_PAGE: {
    HEADER: "लॉग इन करें",
    SUBTITLE: "वापसी पर स्वागत है! कृपया अपने खाते लॉग इन करें।",
    HELP: "सहायता",
    USERNAME: "उपयोगकर्ता नाम",
    PASSWORD: "पासवर्ड",
    FORGOT_PASSWORD: "पासवर्ड भूल गए",
    TERMS_CONDITIONS: "साइन इन करके, मैं नियम एवं शर्तें स्वीकार करता हूं",
  },
  CAPTCHA: {
    ENTER_TEXT: "अक्षर टाइप करें",
    REFRESH: "दोबारा",
  },
  HEADER: {
    HELPDESK: "सहायता केंद्र",
  },
  FOOTER: {
    ALL_RIGHTS_RESERVED: "सर्वाधिकार सुरक्षित।",
    DESIGNED_TCS:
      "डिजाइन, विकास, रखरखाव की जिम्मेदारी: टाटा कंसल्टेंसी सर्विसेज।",
  },
};
