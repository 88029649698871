import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ReqrescontrollerService } from "../services/reqrescontroller.service";
import { CryptographyService } from "../services/cryptography.service";
import { DialogService } from "../services/dialog.service";
import { DialogType } from "../dialogs/dialog-options";

@Component({
  selector: "captcha-box",
  templateUrl: "./captcha-box.component.html",
  styleUrls: ["./captcha-box.component.scss"],
})
export class CaptchaBoxComponent implements OnInit {
  @Output() captchaButtonEvent = new EventEmitter();
  @Output() captchaRefreshEvent = new EventEmitter();
  captchaBase64Text: string = "";
  captchaValue: string = "";
  expiryTime: any;

  constructor(
    private _reqService: ReqrescontrollerService,
    private _cryptographyService: CryptographyService,
    public _dialogsService: DialogService
  ) {}

  ngOnInit(): void {
    console.log("captcha oninit");
    this.loadNewCaptcha();
  }

  loadNewCaptcha(): void {
    // console.log("loadNewCaptcha");
    this.captchaValue = "";
    this.captchaBase64Text = undefined;
    this.captchaRefreshEvent.emit(this.captchaValue);

    this._reqService.generateCaptchaV2().subscribe({
      next: (data: any) => {
        console.log(data);
        if (
          data &&
          data.sts &&
          data.sts.status &&
          data.sts.status === "S" &&
          data.respData &&
          data.respData.captchaId &&
          data.respData.captchaBase64
        ) {
          this.captchaBase64Text = data.respData.captchaBase64;
          window.sessionStorage.setItem("c_data_1", data.respData.captchaId);
          window.sessionStorage.removeItem("c_data_3");
        }
      },
      error: (err) => {
        this._dialogsService.openDialog({
          title: "Error",
          body: "Unable to generate captcha.<br><br>This is an unrecoverable error. Please close this page and try again later",
          type: DialogType.ERROR,
          disableClose: true,
        });
      },
    });
  }

  storeCaptchaAnswerInSession(): void {
    window.sessionStorage.setItem(
      "c_data_3",
      this._cryptographyService.convertStringToSha512String(this.captchaValue)
    );
    this.captchaButtonEvent.emit(this.captchaValue);
    // console.log(window.sessionStorage.getItem("Captcha_Response"));
  }
}
