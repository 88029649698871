import { TranslateLoader } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { englishTranslations } from "../translations/en";
import { hindiTranslations } from "../translations/hi";
import { tamilTranslations } from "../translations/ta";

export class InAppTranslationLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return of(this.getTranslationObject(lang));
  }

  getTranslationObject(lang: string): any {
    switch (lang) {
      case "en":
        return englishTranslations;
      case "hi":
        return hindiTranslations;
      case "tm":
        return tamilTranslations;
    }
  }
}
