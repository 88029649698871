@if (_configService.config["scrollingNotifications"]) {
  <div class="scrolling-text">
    <div class="scroll-title">Updates</div>
    <marquee class="scroll-content">{{ scrollText }}</marquee>
  </div>
}
<div class="login">
  @if (_configService.config["leftPane"]) {
    <div
      class="login-left"
      [innerHTML]="
        _sanitizer.bypassSecurityTrustHtml(
          _configService.lang.value == 'en'
            ? Buffer.from(aboutUs, 'base64')
            : Buffer.from(aboutUsTn, 'base64')
        )
      "
    ></div>
  }
  <div class="login-right">
    <mat-card class="mat-elevation-z8">
      <div class="header">
        <div>
          <h1>{{ "LOGIN_PAGE.HEADER" | translate }}</h1>
          <!-- <hr /> -->
        </div>
        <p>{{ "LOGIN_PAGE.SUBTITLE" | translate }}</p>
      </div>
      <div class="top-right-header" (click)="showHelp()">
        <span>{{ "LOGIN_PAGE.HELP" | translate }}</span>
      </div>

      <form
        class="form-container"
        [formGroup]="loginData"
        (ngSubmit)="submit()"
        autocomplete="off"
      >
        <mat-form-field class="full-width-field">
          <mat-label>{{ "LOGIN_PAGE.USERNAME" | translate }}</mat-label>
          <span class="input-prefix" matPrefix>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.12 12.78C12.0403 12.77 11.9597 12.77 11.88 12.78C11.032 12.7515 10.2282 12.3944 9.63856 11.7842C9.04891 11.174 8.71953 10.3585 8.72001 9.51C8.72001 7.7 10.18 6.23 12 6.23C12.8594 6.22851 13.6851 6.56481 14.2988 7.1664C14.9126 7.768 15.2654 8.58669 15.2811 9.44599C15.2969 10.3053 14.9743 11.1364 14.383 11.76C13.7917 12.3837 12.9789 12.75 12.12 12.78ZM18.74 19.38C16.9023 21.0691 14.496 22.0044 12 22C9.40001 22 7.04001 21.01 5.26001 19.38C5.36001 18.44 5.96001 17.52 7.03001 16.8C9.77001 14.98 14.25 14.98 16.97 16.8C18.04 17.52 18.64 18.44 18.74 19.38Z"
                stroke="#474747"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
                stroke="#474747"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <input
            type="text"
            autocomplete="new-password"
            matInput
            placeholder="Please type here"
            formControlName="username"
            (blur)="validateUser()"
            noPaste
          />
          @if (loginData.invalid) {
            <mat-error>{{ loginData.errors }}</mat-error>
          }
        </mat-form-field>
        <mat-form-field class="full-width-field">
          <mat-label>{{ "LOGIN_PAGE.PASSWORD" | translate }}</mat-label>
          <span class="input-prefix" matPrefix>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 11V10C9 8.34 9.5 7 12 7C14.5 7 15 8.34 15 10V11M12 14.6C12.0788 14.6 12.1568 14.5845 12.2296 14.5543C12.3024 14.5242 12.3685 14.48 12.4243 14.4243C12.48 14.3685 12.5242 14.3024 12.5543 14.2296C12.5845 14.1568 12.6 14.0788 12.6 14C12.6 13.9212 12.5845 13.8432 12.5543 13.7704C12.5242 13.6976 12.48 13.6315 12.4243 13.5757C12.3685 13.52 12.3024 13.4758 12.2296 13.4457C12.1568 13.4155 12.0788 13.4 12 13.4C11.8409 13.4 11.6883 13.4632 11.5757 13.5757C11.4632 13.6883 11.4 13.8409 11.4 14C11.4 14.1591 11.4632 14.3117 11.5757 14.4243C11.6883 14.5368 11.8409 14.6 12 14.6Z"
                stroke="#474747"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M14.5 17H9.5C7.5 17 7 16.5 7 14.5V13.5C7 11.5 7.5 11 9.5 11H14.5C16.5 11 17 11.5 17 13.5V14.5C17 16.5 16.5 17 14.5 17Z"
                stroke="#474747"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 22C17.523 22 22 17.523 22 12C22 6.477 17.523 2 12 2C6.477 2 2 6.477 2 12C2 17.523 6.477 22 12 22Z"
                stroke="#474747"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
          <input
            [type]="isPasswordVisible ? 'text' : 'password'"
            autocomplete="new-password"
            matInput
            placeholder="Please type here"
            formControlName="password"
            noPaste
          />
          <span class="input-suffix" matSuffix>
            @if (!isPasswordVisible) {
              <svg
                width="22"
                height="22"
                (click)="viewPassword()"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.27932 12.8352C7.92182 12.3127 7.72015 11.6802 7.72015 11.0018C7.72015 9.18684 9.18682 7.72017 11.0018 7.72017C12.8168 7.72017 14.2835 9.18684 14.2835 11.0018C14.2835 12.8168 12.8168 14.2835 11.0018 14.2835"
                  stroke="#474747"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.0967 5.11499C14.5475 4.01499 12.8058 3.41916 11 3.41916C7.76418 3.41916 4.74834 5.32583 2.64918 8.62583C1.82418 9.91833 1.82418 12.0908 2.64918 13.3833C4.74834 16.6833 7.76418 18.59 11 18.59C14.2358 18.59 17.2517 16.6833 19.3508 13.3833C20.1758 12.0908 20.1758 9.91833 19.3508 8.62583"
                  stroke="#474747"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            } @else {
              <svg
                width="22"
                height="22"
                (click)="viewPassword()"
                src="assets/images/hide.svg"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.13331 16.2791C4.21665 15.4907 3.38248 14.5191 2.64915 13.3732C1.82415 12.0807 1.82415 9.90824 2.64915 8.61574C3.73081 6.91991 5.05081 5.59074 6.52665 4.70158M16.335 5.28916C14.7308 4.07916 12.8975 3.41916 11 3.41916M7.72015 17.9025C8.76514 18.3425 9.87431 18.5808 11.0018 18.5808C14.2376 18.5808 17.2535 16.6742 19.3526 13.3742C20.1776 12.0817 20.1776 9.90916 19.3526 8.61666C19.0501 8.13999 18.7201 7.69083 18.381 7.26916M13.321 8.68266L8.68265 13.321C8.37809 13.0164 8.1365 12.6549 7.97167 12.2569C7.80685 11.859 7.72201 11.4325 7.72201 11.0018C7.72201 10.5711 7.80685 10.1446 7.97167 9.7467C8.1365 9.34878 8.37809 8.98722 8.68265 8.68266C8.9872 8.3781 9.34876 8.13651 9.74669 7.97169C10.1446 7.80686 10.5711 7.72203 11.0018 7.72203C11.4325 7.72203 11.859 7.80686 12.2569 7.97169C12.6549 8.13651 13.0164 8.3781 13.321 8.68266Z"
                  stroke="#474747"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.2184 11.6417C14.0942 12.2811 13.7818 12.8689 13.3212 13.3295C12.8606 13.7901 12.2728 14.1025 11.6334 14.2267M8.68081 13.3201L1.83331 20.1676M20.1676 1.83334L13.3201 8.68084"
                  stroke="#474747"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          </span>
          @if (loginData.invalid) {
            <mat-error>{{ loginData.errors }}</mat-error>
          }
        </mat-form-field>
        <div class="forget-password" (click)="forgotPw()">
          <a>{{ "LOGIN_PAGE.FORGOT_PASSWORD" | translate }}?</a>
        </div>

        <captcha-box (captchaButtonEvent)="receivedMessageHandler($event)">
        </captcha-box>

        <span class="terms-conditions">{{
          "LOGIN_PAGE.TERMS_CONDITIONS" | translate
        }}</span>
        <button mat-button type="submit" color="primary">
          {{ "LOGIN_PAGE.HEADER" | translate }}
        </button>
        <button mat-button type="button" class="reset-btn" (click)="reset()">
          {{ "LOGIN_PAGE.RESET" | translate }}
        </button>
      </form>
    </mat-card>
  </div>
</div>
