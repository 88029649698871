import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { DialogService } from "./dialog.service";

const urlSegmentsForIgnoringLoader: string[] = ["captcha"];

@Injectable({
  providedIn: "root",
})
export class InterceptorService implements HttpInterceptor {
  constructor(public dialogsService: DialogService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let isLoaderShown: boolean = true;

    if (req.method === "GET") {
      //loader ignored for all GET requests except the request to fetch UI
      isLoaderShown = false;
    }

    for (let segment of urlSegmentsForIgnoringLoader) {
      //factoring in specific exclusions for loader.
      if (req.url.includes(segment)) {
        isLoaderShown = false;
      }
    }

    if (isLoaderShown) {
      this.dialogsService.showLoader();
    }
    return next.handle(req).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      map((event) => {
        if (event instanceof HttpResponse) {
          if (event.status != 200) {
          } else {
          }
        } else {
        }
        return event;
      }),
      finalize(() => {
        if (isLoaderShown) {
          this.dialogsService.hideLoader();
        }
      })
    );
  }
}
