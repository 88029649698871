<div class="footer">
  <div class="footer-inner">
    <p>
      v{{ appVersion }} |
      <!-- {{ "FOOTER.ALL_RIGHTS_RESERVED" | translate }} -->
      {{ footerLabel }}
      {{ "FOOTER.DESIGNED_TCS" | translate }}
    </p>
  </div>
</div>
