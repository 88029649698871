import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material/material.module";
import { AlertDialogComponent } from "./core/dialogs/alert-dialog/alert-dialog.component";
import { ProgressbarComponent } from "./core/loaders/progressbar/progressbar.component";
import { CaptchaBoxComponent } from "./core/captcha-box/captcha-box.component";
import { FooterComponent } from "./footer/footer.component";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { ErrorService } from "./core/services/error.service";
import { LoaderComponent } from "./core/loader/loader.component";
import { InterceptorService } from "./core/services/interceptor.service";
import { HeaderComponent } from "./header/header.component";
import { TopStripComponent } from "./top-strip/top-strip.component";
import { OverlayModule } from "@angular/cdk/overlay";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { InAppTranslationLoader } from "./core/translate/loader/in-app-translation-loader";
import { UpdatedHeaderComponent } from "./updated-header/updated-header.component";
import { NoPasteDirective } from "./no-paste.directive";

@NgModule({
  declarations: [
    AppComponent,
    AlertDialogComponent,
    CaptchaBoxComponent,
    ProgressbarComponent,
    CaptchaBoxComponent,
    FooterComponent,
    LoginComponent,
    LoaderComponent,
    HeaderComponent,
    TopStripComponent,
    UpdatedHeaderComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useClass: InAppTranslationLoader },
    }),
    NoPasteDirective,
  ],
  providers: [
    ErrorService,
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
