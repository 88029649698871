import { OverlayContainer } from "@angular/cdk/overlay";
import { HostBinding, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class GlobalThemeControlService {
  currentTheme = "";
  // @HostBinding("class") componentCssClass;

  constructor() // public overlayContainer: OverlayContainer
  {}

  /**
   * Function that toggles the current mode
   * Exposed publicly
   */
  toggleDisplayTheme() {
    if (document.body.classList.contains("high-contrast")) {
      document.body.classList.remove("high-contrast");
      this.currentTheme = "";
    } else {
      document.body.classList.add("high-contrast");
      this.currentTheme = "high-contrast";
    }
  }

  // onSetTheme(theme) {
  //   this.overlayContainer.getContainerElement().classList.add(theme);
  //   this.componentCssClass = theme;
  // }
}
