export const englishTranslations: any = {
  LOGIN_PAGE: {
    HEADER: "LOGIN",
    SUBTITLE: "Welcome back! Please log in to your account.",
    HELP: "Help",
    USERNAME: "Username",
    PASSWORD: "Password",
    FORGOT_PASSWORD: "Forgot Password",
    TERMS_CONDITIONS: "By signing in, I accept the Terms & Conditions",
    RESET: "RESET",
  },
  CAPTCHA: {
    ENTER_TEXT: "Enter text",
    REFRESH: "Refresh",
  },
  HEADER: {
    HELPDESK: "Helpdesk",
  },
  FOOTER: {
    ALL_RIGHTS_RESERVED:
      "Finance Department, Government of Tamil Nadu. All Rights Reserved.",
    DESIGNED_TCS:
      "Designed, developed and maintained by Tata Consultancy Services Ltd.",
  },
};
