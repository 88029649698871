import { Injectable } from "@angular/core";
import { ReqrescontrollerService } from "./reqrescontroller.service";
import { BehaviorSubject } from "rxjs";
import { Buffer } from "buffer";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config: BehaviorSubject<any> = new BehaviorSubject([]);

  lang: BehaviorSubject<string> = new BehaviorSubject("en");

  constructor(private _reqService: ReqrescontrollerService) {}

  getConfig(): void {
    this._reqService.getConfig().subscribe({
      next: (configResponse) => {
        try {
          this.setConfigFromB64(configResponse);
        } catch (err) {
          console.error(err);
          this.config.next([]);
        }
      },
      error: (err) => {
        console.error("No config retrieved. Setting default value");
        this.config.next([]);
      },
    });

    this.bootstrapConfig();
  }

  setConfigFromB64(configResponse) {
    console.log(
      Buffer.from(configResponse?.configJsonB64, "base64").toString()
    );
    this.config.next(
      JSON.parse(
        Buffer.from(configResponse?.configJsonB64, "base64").toString()
      )
    );
  }

  bootstrapConfig() {
    if (this.config["background"]) {
      document.body.style.background = this.config["background"];
    } else {
      document.body.style.background = "#d6e9f8";
      // document.body.style.background =
      //   "linear-gradient(90deg,rgb(55, 109, 218) 0%,rgb(36, 115, 252) 47%,rgba(15, 206, 222, 1) 96%)";
    }
  }
}
