<div class="header-container mat-elevation-z4">
  <div class="top-strip">
    <div
      *ngIf="headerImgType == 'SQUARE'"
      class="label label-beside-square-logo"
    >
      {{ headerLine1 }}
    </div>
    <div
      *ngIf="headerImgType == 'RECTANGLE'"
      class="label label-beside-rectangle-logo"
    >
      {{ headerLine1 }}
    </div>
    <div class="switches">
      <button
        class="theme-toggle"
        aria-label="High Contrast Theme Toggle"
        matTooltip="Toggle High Contrast Theme"
        (click)="_themeControl.toggleDisplayTheme()"
      >
        <div class="theme-toggle-left"></div>
        <div class="theme-toggle-right"></div>
      </button>

      <button
        class="font-toggle font-smaller"
        aria-label="Reduce font size"
        matTooltip="Reduce font size"
        (click)="this._fontControl.handleZoom('SMALLER')"
      >
        A-
      </button>
      <button
        class="font-toggle font-default"
        aria-label="Default font size"
        matTooltip="Default font size"
        (click)="this._fontControl.handleZoom('DEFAULT')"
      >
        A
      </button>
      <button
        class="font-toggle font-larger"
        aria-label="Increase font size"
        matTooltip="Increase font size"
        (click)="this._fontControl.handleZoom('BIGGER')"
      >
        A+
      </button>

      <div class="language">
        <select
          name="language"
          id="language"
          (change)="langChange($event.target.value)"
        >
          <option value="en">English</option>
          <option value="tm">தமிழ்</option>
        </select>
      </div>
    </div>
  </div>

  <img
    *ngIf="headerImgType == 'SQUARE'"
    src="{{ headerImg1 }}"
    alt=""
    class="logo-small logo-small-square"
    width="100%"
  />

  <img
    *ngIf="headerImgType == 'RECTANGLE'"
    src="{{ headerImg1 }}"
    alt=""
    class="logo-small logo-small-rectangle"
    width="100%"
  />

  <div class="navbar">
    <div *ngIf="headerImgType == 'SQUARE'" class="label">{{ headerLine2 }}</div>
    <div
      *ngIf="headerImgType == 'RECTANGLE'"
      class="label label-beside-rectangle-logo"
    >
      {{ headerLine2 }}
    </div>
    <div class="btns">
      @for (button of links; track $index) {
        <button
          class="nav-btn"
          style="height: 100%"
          (click)="openNavLink(button.link)"
        >
          <mat-icon>{{ button.icon }}</mat-icon>
          <span class="btn-label">{{ button.label }}</span>
        </button>
      }
    </div>
    <div class="btn-menu">
      <button
        class="nav-btn"
        style="height: 100%"
        [matMenuTriggerFor]="appMenu"
      >
        <mat-icon>apps</mat-icon>
        <span class="btn-label">Navigation</span>
      </button>
      <mat-menu #appMenu="matMenu">
        @for (button of links; track $index) {
          <button
            mat-menu-item
            class="nav-btn"
            style="height: 100%"
            (click)="openNavLink(button.link)"
          >
            <mat-icon>{{ button.icon }}</mat-icon>
            <span class="btn-label">{{ button.label }}</span>
          </button>
        }
      </mat-menu>
    </div>
  </div>
</div>
