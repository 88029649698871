import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { ConfigService } from "../core/services/config.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  appVersion: string = environment.appVersion;
  footerLabel: string = "";

  constructor(private _configService: ConfigService) {}

  ngOnInit(): void {
    this.initializeFromConfig();
  }

  initializeFromConfig() {
    var subscriber = this._configService.config.subscribe({
      next: (config) => {
        if (JSON.stringify(config) != "[]") {
          this.footerLabel = config?.footerLabel;
          subscriber.unsubscribe();
        }
      },
    });
  }
}
